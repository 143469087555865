<script>
  import {
    createNewsLetter,
    uploadToCloudinary
  } from "@/services/api/APIService";
  import Icons from "@/components/reuseables/Icons.vue";
  import {
    mapGetters
  } from "vuex";

  export default {
    name: 'NewsLetter',
    components: {
      Icons
    },
    data() {
      return {
        tagInput: '',
        chips: [],
        imageUrl: null,
        loading: false,
        category: [
          'General',
          'Board of director',
          'Investor',
          'Partner',
          'Paddler',
          'Community',
        ],
        newTag: "",
        tagError: "",
        newsLetterContent: {
          title: "",
          description: "",
          category: '',
          image: '',
          compiledEmailList: [],
        },
        isValidEmail: false
      }
    },
    computed: {
      preset() {
        return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      },
      ...mapGetters("support", ["selectedNewsLetter"])
    },

    methods: {
      // async createPost() {
      //   this.loading = true
      //   let imageUploadData = {
      //     file: this.imageUrl,
      //     upload_preset: this.preset
      //   }
      //   console.log(this.newsLetterContent, "hello")
      //   console.log(imageUploadData)
      //   if (imageUploadData.file !== null) {
      //     await uploadToCloudinary(imageUploadData).then(res => {
      //       this.newsLetterContent.image = res.data.url
      //       this.sendOutEmail()
      //     })
      //   }
      //   else{
      //     this.sendOutEmail()
      //   }
      // },
      async sendOutEmail(){
        this.loading = true
        let data = this.newsLetterContent
        await createNewsLetter(data).then(() => {
              this.$displaySnackbar({
                message: "Newsletter sent successfully!",
                success: true
              })
              this.loading = false
              this.$router.push({
                name: 'Paddler'
              })
            }).catch(err => {
              this.$displaySnackbar({
                message: err.message,
                success: false,
              })
            })
          .finally(() => this.loading = false)
      },
      handleImageUpload(event) {
        // const file = event.target.files[0];
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   this.imageUrl = e.target.result;
        // };
        // reader.readAsDataURL(file);
        const files = event.target.files[0];
        try {
          const fileReader = new FileReader();
          fileReader.addEventListener("load", async () => {
            this.imageUrl = fileReader.result;
            let newData = {
              file: this.image,
              upload_preset: this.preset
            };
            console.log(newData);
            const response = await uploadToCloudinary(newData);
            this.newsLetterContent.image = response.data.url;
          });
          fileReader.readAsDataURL(files);
        } catch (err) {
          console.log(err.response);
        }
      },
      handleShowComponent() {
        this.showComponent = false
        this.$nextTick(() => {
          this.showComponent = true
        })
      },
      validateEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isValidEmail = emailRegex.test(this.newTag);
        console.log(this.isValidEmail, this.newTag)
      },
      addTag() {
        const trimmedTag = this.newTag.trim();
        this.validateEmail()
        if (trimmedTag === '' || this.isValidEmail === false) {
          this.setAndClearError("Please enter a valid email address.")
          return;
        }
        if (this.newsLetterContent.compiledEmailList.includes(trimmedTag)) {
          this.setAndClearError("This email has already been added.")
          return;
        }
        this.newsLetterContent.compiledEmailList.push(trimmedTag);
        this.newTag = '',
          this.tagError = ''
      },
      removeTag(index) {
        this.newsLetterContent.compiledEmailList.splice(index, 1)
      },
      setAndClearError(erroMsg) {
        this.tagError = erroMsg;
        setTimeout(() => {
          this.tagError = '';
        }, 4000)
      },

    }
  }
</script>

<template>
  <div class="tw-flex tw-justify-center">
    <div class="page-sculptor">
      <div class="btn-container mb-2">
        <v-btn class="btn" @click="$router.push({name:'SupportDashboard'})">
          <icons name="back" class="mr-2" />
          News Letter</v-btn>
      </div>
      <div class=" page-body">
        <p class="header mb-5">Post Image</p>
        <validation-provider name="image" rules="" v-slot="{ classes, errors }">
          <div class="tw-flex tw-justify-center tw-flex-col">
            <input type="file" class="image-input" id="file" @change="handleImageUpload" />
            <label class="image-label" for="file">
              <img v-if="imageUrl" :src="imageUrl" alt="" class="image" />
              <img v-else src="../../assets/ion_image-outline.png" alt="">
            </label>
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider name="title" rules="required" v-slot="{classes,errors}">
          <p class="header mt-5">Title</p>
          <v-text-field type="text" class="post-title-input mt-5" placeholder="Title" solo hide-details
            v-model="newsLetterContent.title" />
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
        <validation-provider name="description" rules="required" v-slot="{classes,errors}">
          <p class="header mt-5">Description</p>
          <v-textarea type="text" class="post-title-input mt-5" placeholder="Write something great" solo hide-details
            v-model="newsLetterContent.description" />
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
        <validation-provider name="category" rules="required" v-slot="{ classes, errors }">
          <p class="header mt-5">Category</p>
          <v-select class="mt-5" :items="category" hide-details item-text="name" placeholder="Select category" solo
            v-model="newsLetterContent.category">
            <template #append>
              <icons name="select-dropdown" />
            </template>
          </v-select>
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>

        <div class="white-bg mt-7">
          <div class="mb-7">
            <label class="header">Subscribers Emails</label>
            <v-text-field color="#004aad" class="mt-3" type="email" hide-details
              placeholder="Enter a new email address and press Enter or click the plus icon" outlined dense
              v-model="newTag" @keydown.enter.prevent="addTag">
              <template v-slot:append-outer>
                <v-icon color="#004aad" @click="addTag" class="ml-2">mdi-plus-circle-outline</v-icon>
              </template>
            </v-text-field>
            <span class="error-text mb-3" v-if="tagError">{{tagError}}</span>
          </div>
          <div class="tag-list">
            <div v-for="(tag, index) in newsLetterContent.compiledEmailList" :key="index" class="tag mb-3">
              {{ tag }}
              <v-icon @click.stop="removeTag(index)" color="#004aad" class="ml-3">mdi-close-circle-outline</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-end tw-justify-end tw-mt-10">
        <v-btn width="" @click="sendOutEmail" class="post-button" :loading="loading">Send</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .page-body {
    background: #FEFFFF;
    padding: 50px;
    width: 100%;
  }

  .page-sculptor {
    width: 650px;
    height: fit-content;

    @media screen and (max-width:768px) {
      width: 100%;

    }
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 10px;
    background-color: #fff;
  }

  .tag {
    border-radius: 5px;
    background-color: rgba(119, 202, 241, 0.18);
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 12px;
  }

  .remove-tag {
    cursor: pointer;
    margin-left: 8px;
    font-weight: bold;
  }

  .header {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #4F4F4F;

    @media screen and (max-width: 375px) {
      font-size: 14px;
    }
  }

  .btn {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #263238;
    text-transform: none;
  }


  .image-label .image {
    width: 100%;
    height: 192px;
    border-radius: 10px;
  }

  .image-label .image[src] {
    max-width: 100%;
    max-height: 300px;
    display: block;
    margin: auto;
    border-radius: 10px;
    object-fit: cover;
    object-position: top;
  }

  .image-input {
    display: none;
  }

  .image-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 192px;
    border-radius: 10px;
    border: 1px dashed #004aad;
    background: rgba(0, 74, 173, 0.06);

    @media screen and (max-width: 375px) {
      height: 258px;
      border-radius: 10px;
      border: 1px dashed #004aad;
      margin-bottom: 40px;

    }
  }

  .image {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;

    @media screen and (max-width: 375px) {
      margin-top: 40%;
      margin-left: 40%;
    }
  }

  .post-button {
    background: #004AAD !important;
    font-family: Inter sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  span.error-text {
    color: #EB5757;
    font-size: 12px;
    margin-top: 5px;
    font-family: inter;
    font-weight: 500;
  }
</style>